import { createTheme } from "@material-ui/core/styles";

export const getColorByPropertyValue = (value) => {
  if (typeof document !== "undefined") {
    return getComputedStyle(document.documentElement).getPropertyValue(value);
  }
  return null;
};

export const getThemeColor = () => {
  const primaryColor = getColorByPropertyValue("--primaryColor") || "#162029";
  const secondaryColor =
    getColorByPropertyValue("--secondaryColor") || "#04f73d";
  const textColor = getColorByPropertyValue("--testColor") || "#051315";
  return {
    primaryColor,
    secondaryColor,
    textColor,
  };
};

export const theme = createTheme({
  palette: {
    primary: {
      main: "#162029",
    },
    secondary: {
      main: "#04f73d",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1025,
      lg: 1280,
      xl: 1920,
    },
  },
});
