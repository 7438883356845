import axiosClient from "./axiosClient";
export enum UserInteractionKind {
  VISIT_BUYING_POWER = "VISIT_BUYING_POWER",
}
export const createUserInteraction = (type: UserInteractionKind) => {
  return axiosClient.post<{ type: UserInteractionKind }, { result: "OK" }>(
    "/user-interaction",
    { type }
  );
};
